/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import Logo from "../svg/logo.svg"

import "./layout.scss"
import Link from "gatsby-link"

const Layout = ({ children }) => {

  return (
    <>
      <header>
        <Logo height="4rem" width="4rem" />
        <div>
          Veszprém <br />
          Fejlesztői <br />
          Közösség
        </div>
      </header>
      <nav>
        <Link to={'/'} activeStyle={{display: 'none'}}>
          Vissza a kezdőlapra
        </Link>
      </nav>
      <main>{children}</main>
      <footer>
        <br />
        <br />
        <br />
        -- <br />
        Veszprém Fejlesztői Közösség
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
